import {request} from '../network/request.js'

export function orderDayTotal(query) {// 订单记录查询
  return request({
    url: '/parking/saas/orderDayTotal',
    method: 'get',
    params: query,
  })
}

export function orderDetailDay(data, head) {// 当日订单查询
  return request({
    url: '/parking/saas/orderDayTotal/orderDetailDay',
    method: 'post',
    headers: head,
    data: data
  })
}

export function exportOrderCollectDay(data, head) {// excel导出日报表
  return request({
    url: '/parking/saas/orderDayTotal/exportOrderCollectDay',
    method: 'post',
    headers: head,
    data: data
  })
}

export function settleAccounts(data, head) {// 结算接口
  return request({
    url: '/parking/saas/orderDayTotal/settleAccounts',
    method: 'post',
    headers: head,
    data: data
  })
}

export function orderCheckAccountLog(query) {// 建行对账日志
  return request({
    url: '/parking/saas/orderCheckAccountLog',
    method: 'get',
    params: query,
  })
}

export function getCurrentSysAdmin() {// 查询登入用户信息
  return request({
    url: '/parking/saas/sysAdmin/getCurrentSysAdmin',
    method: 'get',
  })
}

export function changePassword(data) {// 修改密码
  return request({
    url: '/parking/saas/sysAdmin/changePassword',
    method: 'post',
    data: data
  })
}

export function findBill(query, head) {// 账单查询
  return request({
    url: '/parking/saas/orderInfo/findBill',
    method: 'get',
    headers: head,
    params: query
  })
}

export function statisticsAmount(query, head) {// 统计金额
  return request({
    url: '/parking/saas/orderInfo/statisticsAmount',
    method: 'get',
    headers: head,
    params: query
  })
}

export function orderTotalReport(data) {// 订单统计日汇总页面
  return request({
    url: '/parking/saas/orderTotalReport',
    method: 'post',
    data: data
  })
}

export function exportOrderCollectDays(data, head) {// 订单统计日汇总excel导出
  return request({
    url: '/parking/saas/orderTotalReport/exportOrderCollectDay',
    method: 'post',
    headers: head,
    data: data
  })
}
